import Vue from "vue";
import Router from "vue-router";
import MainPage from "./components/MainPage.vue";
import ShowData from "./components/ShowData.vue";
import CopyToCrm from "./components/CopyToCrm.vue";
import DoubleOpt from "./components/DoubleOpt.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "mainPage",
      component: MainPage
    },
    {
      path: "/doubleOpt",
      name: "doubleOpt",
      component: DoubleOpt,
      props: route => ({ gd: route.query.gd })
    },
    {
      path: "/showdata",
      name: "showData",
      component: ShowData
    },
    {
      path: "/copyToCrm",
      name: "copytoCrm",
      component: CopyToCrm
    }
    //,
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
});
