<template>
  <transition name="modal">
    <div class="theModalForm">
      <div class="modal-mask" @click="$emit('close')">
        <button class="close-me" @click="$emit('close')">×</button>
        <div ref="modalwrapper" class="modal-wrapper">
          <div v-if="isFetching" class="spinner-box">
            <div class="circle-border">
              <div class="circle-core"></div>
            </div>
          </div>

          <div class="modal-container" @click.stop="">
            <div class="master-flex">
              <div class="master-flex-item-left">
                <div>
                  <img
                    class="logo"
                    src="../assets/Logos/logo_TSC_FC_print.png"
                  />
                </div>
                <div class="apply">
                  Apply to be a Power Partner Program Member
                </div>
                <div class="icon-container">
                  <a
                    class="smicon2 fa-facebook"
                    href="https://www.facebook.com/TSCAutoIDTechnology"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fab', 'facebook-f']" />
                  </a>
                  <a
                    class="smicon2 fa-linkedin"
                    href="https://tw.linkedin.com/company/tsc-auto-id-technology"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                  </a>
                  <a
                    class="smicon2 fa-twitter"
                    href="https://mobile.twitter.com/tscautoid"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                  </a>
                </div>
              </div>

              <div class="master-flex-item-right">
                <div class="form-container">
                  <div class="form-flex-errors">
                    <div style="margin-left: 15pt" v-if="errors.length">
                      <span>Please fill out the following fields:</span>
                      <ul>
                        <li v-for="error in errors" v-bind:key="error">
                          {{ error }}
                        </li>
                      </ul>
                    </div>

                    <div v-if="success" style="width: 90%">
                      <p>Thank you for your registration!</p>
                      <p>
                        To ensure that your request is valid we kindly ask you
                        to confirm your subscription by clicking on the
                        confirmation link in the email which we have sent to
                        you.
                      </p>
                    </div>
                  </div>

                  <form v-if="success === false" @submit.prevent="checkForm">
                    <div class="field-container-left">
                      <div class="abs-left">
                        <input
                          class="field-regular"
                          name="firstName"
                          type="text"
                          placeholder="First Name*"
                          v-model="registrationDto.firstName"
                        />
                      </div>
                    </div>

                    <div class="field-container-right">
                      <div class="abs-right">
                        <input
                          class="field-regular"
                          name="lastName"
                          type="text"
                          placeholder="Last Name*"
                          v-model="registrationDto.lastName"
                        />
                      </div>
                    </div>

                    <div class="field-container-left">
                      <div class="abs-left">
                        <input
                          class="field-regular"
                          name="companyNameName"
                          type="text"
                          placeholder="Company*"
                          v-model="registrationDto.companyName"
                        />
                      </div>
                    </div>

                    <div class="field-container-right">
                      <div class="abs-right" style="padding-right: 18pt">
                        <select
                          class="field-select"
                          style="max-height: 100pt"
                          type="select"
                          v-model="registrationDto.country"
                        >
                          <!-- eslint-disable -->
                          <option disabled>Country*</option>
                          <option value="AF">Afghanistan</option>
                          <!-- <option value="Åland Islands">Åland Islands</option> -->
                          <option value="AL">Albania</option>
                          <option value="DZ">Algeria</option>
                          <!-- <option value="American Samoa">American Samoa</option> -->
                          <option value="AD">Andorra</option>
                          <option value="AO">Angola</option>
                          <!-- <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">Antigua and Barbuda</option> -->
                          <option value="AR">Argentina</option>
                          <option value="AM">Armenia</option>
                          <option value="AW">Aruba</option>
                          <option value="AU">Australia</option>
                          <option value="AT">Austria</option>
                          <option value="AZ">Azerbaijan</option>
                          <option value="BS">Bahamas</option>
                          <option value="BH">Bahrain</option>
                          <option value="BD">Bangladesh</option>
                          <option value="BB">Barbados</option>
                          <option value="BY">Belarus</option>
                          <option value="BE">Belgium</option>
                          <option value="BZ">Belize</option>
                          <option value="BJ">Benin</option>
                          <option value="BM">Bermuda</option>
                          <option value="BT">Bhutan</option>
                          <option value="BO">
                            Bolivia, Plurinational State of
                          </option>
                          <!-- <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option> -->
                          <option value="BA">
                            Bosnia and Herzegovina
                          </option>
                          <option value="BW">Botswana</option>
                          <!-- <option value="Bouvet Island">Bouvet Island</option> -->
                          <option value="BR">Brazil</option>
                          <!-- <option value="British Indian Ocean Territory">British Indian Ocean Territory</option> -->
                          <option value="BN">
                            Brunei Darussalam
                          </option>
                          <option value="BG">Bulgaria</option>
                          <option value="BF">Burkina Faso</option>
                          <option value="BI">Burundi</option>
                          <option value="KH">Cambodia</option>
                          <option value="CM">Cameroon</option>
                          <option value="CA">Canada</option>
                          <option value="CV">Cape Verde</option>
                          <option value="KY">Cayman Islands</option>
                          <option value="CF">
                            Central African Republic
                          </option>
                          <option value="TD">Chad</option>
                          <option value="CL">Chile</option>
                          <option value="CN">China</option>
                          <!-- <option value="Christmas Island">Christmas Island</option>
                          <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option> -->
                          <option value="CO">Colombia</option>
                          <option value="KM">Comoros</option>
                          <option value="CG">Congo</option>
                          <option value="CD">
                            Congo, the Democratic Republic of the
                          </option>
                          <!-- <option value="Cook Islands">Cook Islands</option> -->
                          <option value="CR">Costa Rica</option>
                          <option value="CI">Côte d'Ivoire</option>
                          <option value="HR">Croatia</option>
                          <option value="CU">Cuba</option>
                          <!-- <option value="Curaçao">Curaçao</option> -->
                          <option value="CY">Cyprus</option>
                          <option value="CZ">Czech Republic</option>
                          <option value="DK">Denmark</option>
                          <option value="DJ">Djibouti</option>
                          <option value="DM">Dominica</option>
                          <option value="DO">
                            Dominican Republic
                          </option>
                          <option value="EC">Ecuador</option>
                          <option value="EG">Egypt</option>
                          <option value="SV">El Salvador</option>
                          <option value="GQ">
                            Equatorial Guinea
                          </option>
                          <option value="ER">Eritrea</option>
                          <option value="EE">Estonia</option>
                          <option value="ET">Ethiopia</option>
                          <!-- <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option> -->
                          <option value="FO">Faroe Islands</option>
                          <option value="FJ">Fiji</option>
                          <option value="FI">Finland</option>
                          <option value="FR">France</option>
                          <option value="GF">French Guiana</option>
                          <option value="PF">
                            French Polynesia
                          </option>
                          <!-- <option value="French Southern Territories">French Southern Territories</option> -->
                          <option value="GA">Gabon</option>
                          <option value="GM">Gambia</option>
                          <option value="GE">Georgia</option>
                          <option value="DE">Germany</option>
                          <option value="GH">Ghana</option>
                          <option value="GI">Gibraltar</option>
                          <option value="GR">Greece</option>
                          <option value="GL">Greenland</option>
                          <option value="GD">Grenada</option>
                          <option value="GP">Guadeloupe</option>
                          <option value="GU">Guam</option>
                          <option value="GT">Guatemala</option>
                          <!-- <option value="Guernsey">Guernsey</option> -->
                          <option value="GN">Guinea</option>
                          <option value="GW">Guinea-Bissau</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haiti</option>
                          <!-- <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option> -->
                          <option value="VA">
                            Holy See (Vatican City State)
                          </option>
                          <option value="HN">Honduras</option>
                          <option value="HK">Hong Kong</option>
                          <option value="HU">Hungary</option>
                          <option value="IS">Iceland</option>
                          <option value="IN">India</option>
                          <option value="ID">Indonesia</option>
                          <option value="IR">
                            Iran, Islamic Republic of
                          </option>
                          <option value="IQ">Iraq</option>
                          <option value="IE">Ireland</option>
                          <!-- <option value="Isle of Man">Isle of Man</option> -->
                          <option value="IL">Israel</option>
                          <option value="IT">Italy</option>
                          <option value="JM">Jamaica</option>
                          <option value="JP">Japan</option>
                          <!-- <option value="Jersey">Jersey</option> -->
                          <option value="JO">Jordan</option>
                          <option value="KZ">Kazakhstan</option>
                          <option value="KE">Kenya</option>
                          <option value="KI">Kiribati</option>
                          <option
                            value="KP"
                          >
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="KR">
                            Korea, Republic of
                          </option>
                          <option value="XC">Kosovo</option>
                          <option value="KW">Kuwait</option>
                          <option value="KG">Kyrgyzstan</option>
                          <option value="LA">
                            Lao People's Democratic Republic
                          </option>
                          <option value="LV">Latvia</option>
                          <option value="LB">Lebanon</option>
                          <option value="LS">Lesotho</option>
                          <option value="LR">Liberia</option>
                          <option value="LY">Libya</option>
                          <option value="LI">Liechtenstein</option>
                          <option value="LT">Lithuania</option>
                          <option value="LU">Luxembourg</option>
                          <option value="MO">Macao</option>
                          <option
                            value="MK"
                          >
                            Macedonia, the former Yugoslav Republic of
                          </option>
                          <option value="MG">Madagascar</option>
                          <option value="MW">Malawi</option>
                          <option value="MY">Malaysia</option>
                          <option value="MV">Maldives</option>
                          <option value="ML">Mali</option>
                          <option value="MT">Malta</option>
                          <option value="MH">
                            Marshall Islands
                          </option>
                          <option value="MQ">Martinique</option>
                          <option value="MR">Mauritania</option>
                          <option value="MU">Mauritius</option>
                          <option value="YT">Mayotte</option>
                          <option value="MX">Mexico</option>
                          <option value="FM">
                            Micronesia, Federated States of
                          </option>
                          <option value="MD">
                            Moldova, Republic of
                          </option>
                          <option value="MC">Monaco</option>
                          <option value="MN">Mongolia</option>
                          <option value="ME">Montenegro</option>
                          <option value="MS">Montserrat</option>
                          <option value="MA">Morocco</option>
                          <option value="MZ">Mozambique</option>
                          <option value="MM">Myanmar</option>
                          <option value="NA">Namibia</option>
                          <option value="NR">Nauru</option>
                          <option value="NP">Nepal</option>
                          <option value="NL">Netherlands</option>
                          <option value="NC">New Caledonia</option>
                          <option value="NY">New Zealand</option>
                          <option value="NI">Nicaragua</option>
                          <option value="NE">Niger</option>
                          <option value="NG">Nigeria</option>
                          <!-- <option value="Niue">Niue</option> -->
                          <option value="NF">Norfolk Island</option>
                          <!-- <option value="Northern Mariana Islands">Northern Mariana Islands</option> -->
                          <option value="NO">Norway</option>
                          <option value="OM">Oman</option>
                          <option value="PK">Pakistan</option>
                          <option value="PW">Palau</option>
                          <option value="PS">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="PA">Panama</option>
                          <option value="PG">
                            Papua New Guinea
                          </option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Peru</option>
                          <option value="PH">Philippines</option>
                          <option value="PN">Pitcairn</option>
                          <option value="PL">Poland</option>
                          <option value="PT">Portugal</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="QA">Qatar</option>
                          <option value="RE">Réunion</option>
                          <option value="RO">Romania</option>
                          <option value="RU">
                            Russian Federation
                          </option>
                          <option value="RW">Rwanda</option>
                          <!-- <option value="Saint Barthélemy">Saint Barthélemy</option> -->
                          <option
                            value="SH"
                          >
                            Saint Helena, Ascension and Tristan da Cunha
                          </option>
                          <!-- <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> -->
                          <option value="LC">Saint Lucia</option>
                          <!-- <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                          <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                          <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option> -->
                          <option value="WS">Samoa</option>
                          <option value="SM">San Marino</option>
                          <!-- <option value="Sao Tome and Principe">Sao Tome and Principe</option> -->
                          <option value="SA">Saudi Arabia</option>
                          <option value="SN">Senegal</option>
                          <option value="RS">Serbia</option>
                          <option value="SC">Seychelles</option>
                          <option value="SL">Sierra Leone</option>
                          <option value="SG">Singapore</option>
                          <!-- <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option> -->
                          <option value="SK">Slovakia</option>
                          <option value="SI">Slovenia</option>
                          <!-- <option value="Solomon Islands">Solomon Islands</option> -->
                          <option value="SO">Somalia</option>
                          <option value="ZA">South Africa</option>
                          <!-- <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option> -->
                          <option value="SS">South Sudan</option>
                          <option value="ES">Spain</option>
                          <option value="LK">Sri Lanka</option>
                          <option value="SD">Sudan</option>
                          <option value="SR">Suriname</option>
                          <!-- <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option> -->
                          <option value="SZ">Swaziland</option>
                          <option value="SE">Sweden</option>
                          <option value="CH">Switzerland</option>
                          <option value="SY">
                            Syrian Arab Republic
                          </option>
                          <option value="TW">
                            Taiwan, Province of China
                          </option>
                          <option value="TJ">Tajikistan</option>
                          <option value="TZ">
                            Tanzania, United Republic of
                          </option>
                          <option value="TH">Thailand</option>
                          <!-- <option value="Timor-Leste">Timor-Leste</option> -->
                          <option value="TG">Togo</option>
                          <!-- <option value="Tokelau">Tokelau</option> -->
                          <option value="TO">Tonga</option>
                          <option value="TT">
                            Trinidad and Tobago
                          </option>
                          <option value="TN">Tunisia</option>
                          <option value="TR">Turkey</option>
                          <option value="TM">Turkmenistan</option>
                          <!-- <option value="Turks and Caicos Islands">Turks and Caicos Islands</option> -->
                          <option value="TV">Tuvalu</option>
                          <option value="UG">Uganda</option>
                          <option value="UA">Ukraine</option>
                          <option value="AE">
                            United Arab Emirates
                          </option>
                          <option value="GB">United Kingdom</option>
                          <option value="US">United States</option>
                          <!-- <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option> -->
                          <option value="UY">Uruguay</option>
                          <option value="UZ">Uzbekistan</option>
                          <option value="VU">Vanuatu</option>
                          <option value="VE">
                            Venezuela, Bolivarian Republic of
                          </option>
                          <option value="VN">Vietnam</option>
                          <!-- <option value="Virgin Islands, British">Virgin, Islands British</option> -->
                          <option value="VI">
                            Virgin, Islands U.S.
                          </option>
                          <option value="WF">
                            Wallis and Futuna
                          </option>
                          <!-- <option value="Western Sahara">Western Sahara</option> -->
                          <option value="YE">Yemen</option>
                          <option value="ZM">Zambia</option>
                          <option value="ZW">Zimbabwe</option>
                          <!-- eslint-enable -->
                        </select>
                      </div>
                    </div>

                    <div class="field-container-left">
                      <div class="abs-left">
                        <input
                          class="field-regular"
                          name="city"
                          type="text"
                          placeholder="City*"
                          v-model="registrationDto.city"
                        />
                      </div>
                    </div>

                    <div class="field-container-right">
                      <div class="abs-right">
                        <input
                          class="field-regular"
                          name="zip"
                          type="text"
                          placeholder="ZIP Code*"
                          v-model="registrationDto.zip"
                        />
                      </div>
                    </div>

                    <div class="field-container-center">
                      <div class="abs-street">
                        <input
                          class="field-regular"
                          name="street"
                          type="text"
                          placeholder="Street and Number*"
                          v-model="registrationDto.street"
                        />
                      </div>
                    </div>

                    <div class="field-container-left">
                      <div class="abs-left">
                        <input
                          class="field-regular"
                          name="emailAddressAdress"
                          type="text"
                          placeholder="E-Mail Address*"
                          v-model="registrationDto.emailAddress"
                        />
                      </div>
                    </div>

                    <div class="field-container-right">
                      <div class="abs-right">
                        <input
                          class="field-regular"
                          name="phoneNumber"
                          type="text"
                          placeholder="Phone Number*"
                          v-model="registrationDto.phoneNumber"
                        />
                      </div>
                    </div>
                    <div class="field-container-center">
                      <div class="abs-brand">
                        <select
                          style="height: 84pt"
                          class="field-select-multiple"
                          type="select"
                          v-model="interests"
                          multiple
                        >
                          <option
                            class="long-option"
                            value="ZERO"
                            disabled
                            selected
                          >
                            Additional interests (optional, hold CTRL for
                            multiple selection)
                          </option>
                          <option
                            class="short-option"
                            value="ZERO"
                            disabled
                            selected
                          >
                            Additional interests
                          </option>
                          <option value="Supplies">Genuine Supplies</option>
                          <option value="RFID">RFID</option>
                          <option value="Barcode">
                            Barcode validation (ODV)
                          </option>
                          <option value="System">Print and apply system</option>
                        </select>
                      </div>
                    </div>

                    <div class="field-container-distributors">
                      <div class="abs-notes">
                        <textarea
                          class="field-regular"
                          name="notesComments"
                          style="height: 35pt"
                          type="text"
                          placeholder="Distributors of choice (multiple entries are possible)*"
                          v-model="registrationDto.distributors"
                        />
                      </div>
                    </div>

                    <div class="field-container-notes">
                      <div class="abs-notes">
                        <textarea
                          class="field-regular"
                          name="notesComments"
                          style="height: 35pt"
                          type="text"
                          placeholder="Notes/Comments"
                          v-model="registrationDto.notes"
                        />
                      </div>
                    </div>

                    <div class="form-flex-bottom">
                      <a
                        class="privacy-policy"
                        :href="publicPath"
                        target="__blank"
                      >
                        Privacy Policy
                      </a>
                      <br />

                      <div class="custom-checkbox-container">
                        <input
                          id="asd"
                          name="asd"
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="registrationDto.isDataProtectionAccepted"
                        />
                        <label class="custom-checkbox-label" for="asd">
                          I have read and understood the Privacy Policy (<a
                            :href="publicPath"
                            target="_blank"
                            >DE</a
                          >/<a :href="publicPathEn" target="_blank">EN</a>)
                        </label>
                      </div>

                      <div class="custom-checkbox-container">
                        <input
                          id="newsLetterId"
                          name="newsletterName"
                          type="checkbox"
                          class="custom-checkbox2"
                          v-model="registrationDto.newsLetterChecked"
                        />
                        <label class="custom-checkbox-label" for="newsLetterId">
                          <span>Subscribe to </span>
                          <span style="color: red"> IMPORTANT </span>
                          <span
                            >Printronix Auto ID EMEA updates such as
                            <strong
                              >price lists, price updates, new product releases
                              and special offers</strong
                            ></span
                          >
                        </label>
                      </div>

                      <!-- <div class="custom-checkbox-container">
                        <input
                          id="portalId"
                          name="portalName"
                          type="checkbox"
                          class="custom-checkbox"
                          v-model="registrationDto.portalChecked"
                        />
                        <label class="custom-checkbox-label" for="portalId">
                          I would like to register at the TSC Partner Portal
                        </label>
                      </div> -->
                    </div>

                    <div class="form-flex-submit">
                      <span class="captcha-display">{{ getCaptcha }}</span>
                      <input
                        type="text"
                        class="captcha-input"
                        v-model="solved"
                      />
                      <input type="submit" value="Submit" />
                    </div>
                  </form>
                </div>
                <!-- form-container -->
              </div>
              <!-- master-flex-item-right -->
            </div>
            <!-- master-flex -->
          </div>
          <!-- modal-container -->
        </div>
        <!-- modal-wrapper -->
      </div>
      <!-- modal-mask -->
    </div>
    <!-- theModalForm -->
  </transition>
</template>

<script>
export default {
  name: "ModalForm",
  data() {
    return {
      publicPath: process.env.BASE_URL + "PrivacyPolicy_TSCAutoID.pdf",
      publicPathEn: process.env.BASE_URL + "PrivacyPolicy_TSCAutoID_EN.pdf",
      appPath: process.env.VUE_APP_API_ENDPOINT,
      errors: [],
      success: false,
      first: -1,
      second: -2,
      solved: null,
      isFetching: false,
      registrationDto: {
        firstName: null,
        lastName: null,
        companyName: null,
        emailAddress: null,
        country: "Country*",
        city: null,
        zip: null,
        street: null,
        phoneNumber: null,
        notes: null,
        brand: "Choose the brand*",
        isDataProtectionAccepted: false,
        newsLetterChecked: false,
        portalChecked: false,
        distributors: null,
        interests: null
      },
      interests: new Array(
        "Additional interests (optional, hold CTRL for multiple selection)"
      )
    };
  },

  computed: {
    getCaptcha() {
      this.generate();
      return this.first.toString() + " + " + this.second.toString() + " =";
    }
  },

  methods: {
    generate() {
      this.solved = null;
      this.first = Math.floor(Math.random() * (20 - 1) + 1);
      this.second = Math.floor(Math.random() * (20 - 1) + 1);
    },

    checkCaptcha() {
      let result = this.first + this.second === Number(this.solved);
      return result;
    },

    registerMe() {
      var comp = this;
      var invocation = new XMLHttpRequest();
      var url = this.appPath + "/registration";
      var body = JSON.stringify(this.registrationDto);

      invocation.open("POST", url, true);
      invocation.setRequestHeader("X-PINGOTHER", "pingpong");
      invocation.setRequestHeader(
        "Content-Type",
        "application/json;charset=UTF-8"
      );
      invocation.onreadystatechange = function () {
        if (
          invocation.readyState === XMLHttpRequest.DONE &&
          invocation.status === 200
        ) {
          comp.errors = [];
          comp.success = true;
        } else if (
          invocation.readyState === XMLHttpRequest.DONE &&
          invocation.status === 400
        ) {
          comp.success = false;
          comp.errors = [];
          let errorColl = JSON.parse(invocation.responseText);
          if (errorColl && errorColl.errors) {
            for (var err of errorColl.errors) comp.errors.push(err);
          } else {
            comp.errors.push("An unknown error occurred");
          }
        } else if (invocation.readyState === XMLHttpRequest.DONE) {
          comp.success = false;
          comp.errors = [];
          comp.errors.push("An unknown error occurred");
        }
        comp.isFetching = false;
      };
      invocation.send(body);
    },

    validEmail() {
      if (!this.registrationDto.emailAddress) return false;

      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.registrationDto.emailAddress);
    },

    // rough check whether the phone number looks reasonable:
    // - contains at least 6 digits
    isReasonablePhone() {
      if (!this.registrationDto.phoneNumber) return false;

      if (this.registrationDto.phoneNumber.replace(/[^0-9]/g, "").length < 6)
        return false;

      return true;
    },

    checkCharacter(s) {
      if (!s) return true;

      // eslint-disable-next-line no-control-regex
      const reg = /[^\u0000-~\u0080-þĀ-žƀ-Ɏ]/g;
      let replaced = s.replace(reg, "л"); // cyrillic by intent
      return replaced.indexOf("л") <= -1;
    },

    getCharacterSetError() {
      return "All input fields accept latin characters only.\r\nOther character sets such as Kyrillic or Arabic are not supported.";
    },

    addToErrors(s) {
      if (this.errors.indexOf(s) <= -1) {
        this.errors.push(s);
      }
    },

    /* eslint-disable*/
    stringifySpecialInterest(interests) {
      // is null
      if (!interests) return null;
      // single string
      else if (typeof interests === "string" || interests instanceof String) {
        if (interests.includes("Additional interests"))
          return null; // default value selected, return null
        else return interests; // singe selection
      }

      // array, i.e. multiple selection
      else if (interests instanceof Array) {
        // empty array, should never happen
        if (interests.length === 0) return null;
        // non-empty array, at least one selected item
        else {
          // default value selected, needs to be filtered out
          if (
            interests.some(
              (x) => x.includes("ZERO") || x.includes("Additional interests")
            )
          ) {
            // only default value selected, return null
            if (interests.length === 1) return null;
            // at least one value other than the default value selected, filter and join
            else {
              var sub = interests.filter(
                (x) => x !== "ZERO" && !x.includes("Additional interests")
              );
              return sub.join(";");
            }
          }

          // multiple values selected and the default value is not among them: join
          return interests.join(";");
        }
      }

      return null;
    },
    /* eslint-enable*/

    checkForm() {
      if (
        this.registrationDto.firstName &&
        this.checkCharacter(this.registrationDto.firstName) &&
        this.registrationDto.distributors &&
        this.checkCharacter(this.registrationDto.distributors) &&
        this.registrationDto.lastName &&
        this.checkCharacter(this.registrationDto.lastName) &&
        this.registrationDto.companyName &&
        this.checkCharacter(this.registrationDto.companyName) &&
        this.registrationDto.emailAddress &&
        this.registrationDto.country !== "Country*" &&
        this.registrationDto.city &&
        this.checkCharacter(this.registrationDto.city) &&
        this.registrationDto.zip &&
        this.checkCharacter(this.registrationDto.zip) &&
        this.registrationDto.street &&
        this.checkCharacter(this.registrationDto.street) &&
        this.registrationDto.isDataProtectionAccepted &&
        this.isReasonablePhone() &&
        this.validEmail() &&
        this.checkCaptcha() &&
        this.checkCharacter(this.registrationDto.notes) &&
        this.registrationDto.newsLetterChecked
      ) {
        this.registrationDto.interests = this.stringifySpecialInterest(
          this.interests
        );
        this.isFetching = true;
        this.registerMe();
        //return this.success;
      }
      this.errors = [];
      if (!this.registrationDto.firstName) this.errors.push("First Name");
      else if (!this.checkCharacter(this.registrationDto.firstName))
        this.addToErrors(this.getCharacterSetError());
      if (!this.registrationDto.distributors)
        this.errors.push("Distributors of Choice");
      else if (!this.checkCharacter(this.registrationDto.distributors))
        this.addToErrors(this.getCharacterSetError());
      if (!this.registrationDto.lastName) this.errors.push("Last Name");
      else if (!this.checkCharacter(this.registrationDto.lastName))
        this.addToErrors(this.getCharacterSetError());
      if (!this.registrationDto.companyName) this.errors.push("Company Name");
      else if (!this.checkCharacter(this.registrationDto.companyName))
        this.addToErrors(this.getCharacterSetError());
      if (
        !this.registrationDto.country ||
        this.registrationDto.country === "Country*"
      )
        this.errors.push("Country");

      if (!this.registrationDto.city) this.errors.push("City");
      else if (!this.checkCharacter(this.registrationDto.city))
        this.addToErrors(this.getCharacterSetError());

      if (!this.registrationDto.zip) this.errors.push("ZIP Code");
      else if (!this.checkCharacter(this.registrationDto.zip))
        this.addToErrors(this.getCharacterSetError());

      if (!this.registrationDto.street) this.errors.push("Street and Number");
      else if (!this.checkCharacter(this.registrationDto.street))
        this.addToErrors(this.getCharacterSetError());

      if (!this.validEmail()) {
        if (!this.registrationDto.emailAddress)
          this.errors.push("E-Mail Address");
        else this.errors.push("E-Mail Address (invalid format)");
      }
      if (!this.isReasonablePhone()) {
        if (!this.registrationDto.phoneNumber) this.errors.push("Phone Number");
        else this.errors.push("Phone Number (requires at least 6 digiits)");
      }
      if (!this.checkCharacter(this.registrationDto.notes))
        this.addToErrors(this.getCharacterSetError());
      if (!this.registrationDto.isDataProtectionAccepted)
        this.errors.push("Privacy Policy");
      if (!this.checkCaptcha()) {
        this.errors.push("Captcha");
        this.generate();
      }
      if (!this.registrationDto.newsLetterChecked)
        this.errors.push(
          "Newletter (Marketing - If you need to contact us to change your preference regarding this selection please do at: Marketing@tscprinters.eu)"
        );
      // e.preventDefault();
      this.$refs.modalwrapper.scrollTop = 0;
      return false;
    }
  } // methods
}; // export
</script>

<style>
.custom-checkbox-container {
  margin-top: 10pt;
  margin-bottom: 8pt;
  margin-left: 18pt;
  margin-right: 14pt;
}
.custom-checkbox {
  display: none;
}
.custom-checkbox + label::before {
  width: 13pt;
  height: 13pt;
  border: 2pt solid orange;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 5pt;
}
.custom-checkbox:checked + label::before {
  box-shadow: inset 0px 0px 0px 2pt #fff;
  background-color: orange;
}
.custom-checkbox-label {
  color: rgb(0, 84, 165);
  font-size: 80%;
}

.custom-checkbox2 {
  display: none;
}
.custom-checkbox2 + label::before {
  margin-top: 6.5pt;
  width: 13pt;
  height: 13pt;
  border: 2pt solid orange;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 5pt;
}
.custom-checkbox2:checked + label::before {
  box-shadow: inset 0px 0px 0px 2pt #fff;
  background-color: orange;
}

.close-me {
  border: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  color: white;
  font-weight: 600;
  font-size: 250%;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
  cursor: pointer !important;
}

.close-me:hover {
  opacity: 0.7;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

:focus::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:focus:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

:focus::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

input:focus,
select:focus,
textarea:focus {
  background-color: orange;
  color: black;
}

.field-container-left {
  flex-basis: 50%;
  margin-top: 6pt;
}

.field-container-center,
.field-container-notes,
.field-container-center-large,
.field-container-center-small {
  flex-basis: 100%;
  margin-top: 6pt;
}
.field-container-distributors {
  flex-basis: 100%;
  margin-top: 5pt;
}
.field-container-right {
  flex-basis: 50%;
  margin-top: 6pt;
}
.field-container-notes {
  margin-top: 0pt;
  height: 55pt;
  margin-bottom: 0pt;
}

.abs-left {
  padding-right: 12pt;
  padding-left: 18pt;
}
.abs-right {
  padding-right: 23pt;
  padding-left: 7pt;
}
.abs-brand {
  padding-right: 18pt;
  padding-left: 18pt;
}
.abs-street {
  padding-right: 23pt;
  padding-left: 18pt;
}
.abs-notes {
  padding-right: 23pt;
  padding-left: 18pt;
  padding-bottom: 5pt;
}

.field-regular,
.field-select {
  color: white;
  background: rgb(0, 84, 165);
  height: 22pt;
  border: none;
  width: 100%;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
}

.field-select-multiple {
  color: white;
  background: rgb(0, 84, 165);
  height: 60pt;
  border: none;
  width: 100%;
  overflow-y: auto;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  scrollbar-width: none; /*For Firefox*/
  -ms-overflow-style: none; /*For Internet Explorer 10+*/
}

.field-select-multiple option:disabled {
  color: white;
  /* font-weight: bold; */
}

.field-select-multiple:focus option:disabled {
  color: black;
}

/* .field-select-multiple cus {
  color: violet;
} */
/* :focus {
  color: violet;
} */

.field-regular {
  padding-left: 5pt;
}
.field-select {
  padding-left: 3pt;
  appearance: none;
}
textarea {
  padding-top: 10pt;
  resize: none;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  font-size: small;
}

.privacy-policy {
  font-size: 13pt;
  font-weight: bold;
  text-decoration: none;
  /* margin: 20pt; */
  margin-left: 18pt;
  margin-top: 5pt;
  color: black;
}

.smicon2 {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  text-align: center;
  margin: 7px;
  border-radius: 3px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0.8, 0.8, 0.8, 0.85);
  display: table;
  transition: opacity 40.3s ease;
}

.modal-wrapper {
  position: relative;
  top: 3em;
  vertical-align: middle;
  overflow-y: auto;
  max-height: 88vh;
}

.modal-container {
  width: 85%;
  max-width: 1292px;
  margin: 0px auto;
  background-color: #fff;
  height: auto;
  /* padding-top: 20pt; */
}

.master-flex {
  display: flex;
  flex-wrap: wrap;
}

.form-container {
  /* background-color: lightcoral; DBG */
  margin-top: 30px;
  padding-top: 2pt;
}

form {
  display: flex;
  flex-wrap: wrap;
}

.form-flex-errors {
  flex-basis: 100%;
  flex-grow: 1;
  /* background-color: lightpink; DBG */
  /* min-height: 5px; */
  text-align: left;
  color: black;
  margin: 0;
}

.form-flex-bottom {
  flex-basis: 100%;
  flex-grow: 1;
  /* background-color: lightpink; DBG */
  min-height: 20px;
  text-align: left;
}

.form-flex-submit {
  flex-basis: 100%;
  flex-grow: 1;
  height: auto;
  align-content: right;
  text-align: right;
  /* background-color: red; */
}

input[type="submit"],
.cancel-button {
  background-color: white;
  color: rgb(0, 84, 165);
  /*padding: 10pt 19pt;*/
  /*padding: 6pt 19pt;*/
  padding-top: 6pt;
  padding-bottom: 7pt;
  padding-left: 19pt;
  padding-right: 19pt;

  border: 2px solid;
  border-radius: 4px;
  cursor: pointer !important;
  font-size: 15pt;
  margin-top: 1pt;
  margin-right: 17pt;
  margin-bottom: 15pt;
  transition: 0.2s;
  /* background-color: yellow; */
}

input[type="submit"]:hover,
.cancel-button:hover {
  /* opacity: 0.9; */
  border-color: white;
  background-color: rgb(230, 230, 230);
}

.captcha-input {
  color: white;
  background-color: rgb(0, 84, 165);
  border: none;
  font-size: 12pt;
  margin-right: 12pt;
  margin-bottom: 16pt;
  width: 32pt;
  height: 32pt;
  margin-top: 0;
  position: relative;
  top: -1pt;
  text-align: center;
  /* background-color: bisque; */
}

.captcha-display {
  font-size: 12pt;
  color: rgb(0, 84, 165);
  margin-right: 10pt;
}

.master-flex-item-left {
  min-height: 550px;
  height: auto;
  flex-grow: 1;
  flex-basis: 50%;
}

.master-flex-item-right {
  height: auto;
  flex-grow: 1;
  flex-basis: 50%;
}

.apply {
  font-weight: 900;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20%;
  font-size: 24pt;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  color: #0054a5 !important;
  line-height: 1em;
}

.icon-container {
  margin-top: 100px;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}
.fa-twitter {
  background: #55acee;
  color: white;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
}
.fa-facebook:hover,
.fa-twitter:hover,
.fa-linkedin:hover {
  opacity: 0.7;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner-box {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: fixed;
  top: 200pt;
  left: 50%;
  transform: translate(-50%, 0);
}

.circle-border {
  width: 75px;
  height: 75px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  background: linear-gradient(
    0deg,
    rgba(0, 84, 165, 0.1) 33%,
    rgba(0, 84, 165, 1) 100%
  );
  animation: spin 1.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
}

@media only screen and (max-width: 850px) {
  .master-flex {
    flex-direction: column;
  }
  .master-flex-item-left {
    min-height: 250pt;
    padding: 0;
  }
  .icon-container {
    margin-top: 40pt;
  }
  .apply {
    font-weight: 200px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 25px;
    font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
    color: #0054a5 !important;
    line-height: 1em;
  }

  .long-option {
    display: none;
  }
  .short-option {
    display: block;
  }
}

@media only screen and (min-width: 851px) {
  .long-option {
    display: block;
  }
  .short-option {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .form {
    flex-direction: column;
  }
  .field-container-left {
    flex-basis: 100%;
  }
  .field-container-right {
    flex-basis: 100%;
  }
  .abs-left {
    padding-right: 23pt;
    padding-left: 18pt;
  }
  .abs-right {
    padding-right: 23pt;
    padding-left: 18pt;
  }
}
.logo {
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 7%;
  max-width: 80%;
  height: auto;
}

@media only screen and (max-width: 530px) {
  .logo {
    max-width: 80%;
  }
}
</style>
