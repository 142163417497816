<template>
  <div class="outer-div">
    <div class="inner-div" v-if="errors.length">
      <li v-for="item in errors" v-bind:key="item">
        {{ item }}
      </li>
    </div>

    <div class="inner-div" v-else-if="registration">
      <p>Thank you for your registration.</p>
      <p>You have registered for the following options:</p>

      <ul>
        <li>Becoming a TSC Printronix Auto ID Power Partner</li>
        <li v-if="registration.newsLetter">
          Receiving regular information about TSC and Printronix Auto ID and the
          related products via our newsletters
        </li>
        <li v-if="registration.portal">
          Request access to the TSC partner portal
        </li>
      </ul>

      <p>
        We have received your registration. Please understand that we will need
        some time to process your request. <br />We will get back to you as soon
        as possible.
      </p>
    </div>

    <div class="inner-div" v-else>
      One Moment please, your registration is being processed...
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleOpt",
  mounted() {
    if (this.gd) {
      //this.getRegistration();
      this.postIt();
    } else this.errors.push("Invalid Parameter");
  },
  props: {
    gd: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      appPath: process.env.VUE_APP_API_ENDPOINT,
      registration: undefined,
      errors: []
    };
  },
  methods: {
    // getRegistration() {
    //   let self = this;
    //   const url = this.appPath + "/doubleOpt?gd=" + this.gd;
    //   let options = {
    //     method: "GET",
    //     mode: "cors",
    //     headers: {
    //       Accept: "application/json",
    //       "upgrade-insecure-requests": "1"
    //     }
    //   };
    //   let respStatus = 0;
    //   fetch(url, options)
    //     .then(resp => {
    //       respStatus = resp.status;
    //       return resp.json();
    //     })
    //     .then(data => {
    //       switch (respStatus) {
    //         case 200:
    //           self.registration = data;
    //           break;
    //         case 400:
    //           for (var i = 0; i < data.length; i++) self.errors.push(data[i]);
    //           break;
    //         case 500:
    //           self.errors.push("Server Error");
    //           break;
    //         default:
    //           self.errors.push("Unknown error occurred");
    //           break;
    //       }
    //     })
    //     .catch(error => {
    //       self.errors.push(error);
    //     });
    // },
    postIt() {
      var self = this;
      var invocation = new XMLHttpRequest();
      var url = this.appPath + "/doubleOpt";
      var body = JSON.stringify(this.gd);

      invocation.open("POST", url, true);
      invocation.setRequestHeader("X-PINGOTHER", "pingpong");
      invocation.setRequestHeader(
        "Content-Type",
        "application/json;charset=UTF-8"
      );
      invocation.onreadystatechange = function () {
        if (
          invocation.readyState === XMLHttpRequest.DONE &&
          invocation.status === 200
        ) {
          self.errors = [];
          self.success = true;
          self.registration = JSON.parse(invocation.responseText);
        } else if (
          invocation.readyState === XMLHttpRequest.DONE &&
          invocation.status === 400
        ) {
          self.success = false;
          self.errors = [];
          let errorColl = JSON.parse(invocation.responseText);
          if (errorColl && errorColl.length && errorColl.length > 0) {
            for (var i = 0; i < errorColl.length; i++)
              self.errors.push(errorColl[i]);
          } else {
            self.errors.push("An unknown error occurred");
          }
        } else if (invocation.readyState === XMLHttpRequest.DONE) {
          self.success = false;
          self.errors = [];
          self.errors.push("An unknown error occurred");
        }
        self.isFetching = false;
      };
      invocation.send(body);
    }
  }
};
</script>

<style scoped>
.outer-div {
  margin-top: 50pt;
  width: 100%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}

.inner-div {
  display: inline-block;
  margin: 5vh 0 auto auto;
  padding: 3px;
  text-align: left;
}

.custom-list {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}

.list-bullit-yes li:before {
  content: "+";
  position: absolute;
  left: 0;
}

.list-bullit-no li:before {
  content: "+";
  position: absolute;
  left: 0;
}
</style>
