<template>
  <div class="mainpage">
    <div class="main-content">
      <div class="power-new">
        <div style="text-align: left">
          <div class="power-text-h1">POWER YOUR PROFITS</div>
          <h2 class="power-text-h2">
            with TSC and Printronix Auto ID Printers
          </h2>
          <div class="power-text-normal">
            There’s never been a better time to grow your business than today.
          </div>
          <div class="power-text-normal power-bold">
            By joining our Power Partner Program, you can increase your margins
            to startling heights.
          </div>
        </div>
        <div class="power-logo">
          <a href="#" class="tsc-link">
            <img class="logo-top" src="../assets/Logos/logo_TSC_FC_print.png" />
          </a>
        </div>
        <div class="join-content-link-div">
          <button @click="showModal = true" class="power-button">
            Become a Partner
          </button>
          <transition name="modal">
            <ModalForm v-if="showModal" @close="showModal = false" />
          </transition>
        </div>
      </div>

      <div class="rmaster">
        <div class="rheader">
          <div class="reasons-header1">REASONS TO JOIN</div>
        </div>

        <div class="rleft">
          <div class="reasons-header2">
            Get Access To The Market’s Most Reliable And Diverse Thermal Barcode
            Printers
          </div>
        </div>

        <div class="rright">
          <div class="r-margins-container">
            <span class="power-bold reasons-text">
              TSC and Printronix Auto ID are brands you can trust.
            </span>
            <span class="reasons-text">
              An advanced approach to new product innovation separates us as
              high-performing organization from the rest of the pack.
            </span>
            <P />
            <span class="reasons-text">
              By joining our partner program, you can sell
            </span>
            <span class="reasons-text power-bold">
              industry-leading thermal printing and barcode validation solutions
            </span>
            <span class="reasons-text">to enterprises at any level.</span>
          </div>
        </div>

        <div class="rleft">
          <div class="reasons-header2">
            Get New Leads And New Business Opportunities
          </div>
        </div>

        <div class="rright">
          <div class="r-margins-container">
            <span class="reasons-text">
              In your industry, we know that competition is steep, so securing
              new opportunities to increase your margins is crucial. That’s why
              we bring you
            </span>
            <span class="reasons-text power-bold">
              new leads with leading end users to power your profits.
            </span>
          </div>
        </div>

        <div class="rleft-last">
          <div class="reasons-header2">The Attention You Deserve</div>
        </div>

        <div class="rright-last">
          <div class="r-margins-container">
            <span class="reasons-text power-bold">
              We give our partners the attention they deserve,
            </span>
            <span class="reasons-text">
              by handling their applications from training and product support,
              to service and installation.
            </span>
          </div>
        </div>
      </div>

      <div class="ready">
        <h1 class="ready-header">READY TO GROW?</h1>
        <div class="ready-text">
          It’s time to power your profits and increase your market share.
        </div>
      </div>

      <div class="tsc">
        <div class="tsc-inner">
          <div class="tsc-logo">
            <a href="#" class="tsc-link">
              <img
                class="logo-top"
                src="../assets/Logos/logo_TSC_FC_print.png"
              />
            </a>
          </div>
          <div class="join-content-link-div-middle">
            <button @click="showModal2 = true" class="middle-button">
              Become a Partner
            </button>
            <transition name="modal">
              <ModalForm v-if="showModal2" @close="showModal2 = false" />
            </transition>
          </div>
        </div>
      </div>

      <div class="advert">
        <div class="advert-inner">
          <h2 class="advert-header">
            We’ll be right there to help you close the deal
          </h2>
          <span class="advert-text">
            All Power Partners have on-site sales and technical support from a
            regional sales manager and our customer application engineers.
          </span>
          <br />
          <span class="advert-text">
            Got a big deal you’re trying to close?
          </span>
          <br />
          <span class="advert-text">
            We’ll come onsite with you to help you get new business and give
            your customers the solution they’re looking for.
          </span>
          <div class="space"></div>
          <h2 class="advert-header">
            Easily accessible service and support for whatever you need
          </h2>
          <span class="advert-text">
            Got a question? We’re here to help. Partners can take advantage of
            direct technical support from our support staff and engineering.
          </span>
        </div>
      </div>

      <div class="levels-container">
        <div class="levels">
          <h1 class="advert-header">
            FIND THE PARTNER PROGRAM THAT'S RIGHT FOR YOU
          </h1>
          <p class="advert-text">
            Check out our three levels to see which you’d want to qualify for.
          </p>
          <p class="levels-small">
            * Power Partner Program may vary by global region.
          </p>
        </div>
        <div class="table-container">
          <table class="levels-table">
            <thead class="th">
              <tr class="thtr">
                <td class="thtd1">POWER PARTNER LEVELS</td>
                <td class="thtd2">PLATINUM</td>
                <td class="thtd3">GOLD</td>
                <td class="thtd4">SILVER</td>
              </tr>
            </thead>
            <tbody class="tb">
              <tr class="tbtr">
                <td class="tbtdr">Annual Purchase Commitment</td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/Coin3.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/Coin2.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/Coin1.png" />
                </td>
              </tr>
              <tr class="tbtr">
                <td class="tbtdr">
                  OR Brand Loyalty: Lead with us for 80% of barcode revenue
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd"></td>
                <td class="tbtd"></td>
              </tr>
              <tr class="tbtr">
                <td class="tbtdr">Collaborate on deals</td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd"></td>
              </tr>
              <tr class="tbtr">
                <td class="tbtdr">
                  Certified to carry out support and service
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd"></td>
              </tr>
              <tr class="tbtr">
                <td class="tbtdr">Required to submit partner profile</td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
              </tr>
              <tr class="tbtr">
                <td class="tbtdr">Adheres to MAP Policy</td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
                <td class="tbtd">
                  <img class="tbimg" src="../assets/bolt.png" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="join-container">
        <div class="join-content">
          <h1 class="join-content-header">
            JOIN THE POWER PARTNER PROGRAM TODAY!
          </h1>
          <div class="join-content-text">
            It’s time to power your profits and increase your market share.
          </div>
          <a href="#" class="tsc-link">
            <img class="logo-top" src="../assets/Logos/logo_TSC_FC_print.png" />
          </a>

          <div class="join-content-link-div-bottom">
            <button @click="showModal3 = true" class="power-button">
              Become a Partner
            </button>
            <transition name="modal">
              <ModalForm v-if="showModal3" @close="showModal3 = false" />
            </transition>
          </div>
        </div>
      </div>

      <div class="black-end">
        Privacy Policy<a
          class="imprint-format"
          :href="publicPath"
          target="_blank"
          >(DE</a
        >/<a class="imprint-format" :href="publicPathEN" target="_blank">EN)</a>
        <span></span>
        <a class="imprint-format" :href="imprint" target="_blank">Imprint</a>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import ModalForm from "./ModalForm";

export default {
  name: "MainPage",
  components: {
    ModalForm
  },
  props: {
    // msg: String,
    // _showModal: Boolean
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      showModal3: false,
      publicPath: process.env.BASE_URL + "PrivacyPolicy_TSCAutoID.pdf",
      publicPathEN: process.env.BASE_URL + "PrivacyPolicy_TSCAutoID_EN.pdf",
      imprint: process.env.BASE_URL + "imprint.html"
    };
  },
  methods: {
    toggleModal() {
      this.showModal2 = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content {
  margin-top: 41pt;
}

.power-new {
  background-image: url(../assets/fist.png), url(../assets/banner.png);
  background-position: right 5vw top 20%, left top;
  background-size: auto 15vw, cover;
  background-repeat: no-repeat;
  width: auto;
  color: white;
  padding-left: 7vw;
  padding-top: 5vh;
  height: auto;
}

.power-button {
  /* margin-top: 10px; */
  color: white;
  border-width: 2px !important;
  border-color: white;
  border-radius: 2px;
  font-size: 18px;
  position: relative;
  padding: 0.8em 1em;
  border: 2px solid;
  cursor: pointer !important;
  /* transition: 0.3s; */
  background-color: Transparent;
  margin-bottom: 4vw;
}

.power-button:hover {
  /* opacity: 0.7; */
  transition: 0.4s;
  background-color: rgba(200, 200, 200, 0.2);
  /* border: none; */
  /* border: 50px transparent; */
  border-color: rgba(200, 200, 200, 0);
}

.middle-button {
  /* margin-top: 10px; */
  color: #0054a5;
  border-width: 2px !important;
  border-radius: 2px;
  font-size: 18px;
  position: relative;
  padding: 0.8em 1em;
  border: 2px solid;
  cursor: pointer !important;
  /* transition: 0.3s; */
  background-color: Transparent;
  /* margin-bottom: 4vw; */
}

.middle-button:hover {
  /* opacity: 0.7; */
  transition: 0.4s;
  background-color: rgba(200, 200, 200, 0.2);
  /* border: none; */
  /* border: 50px transparent; */
  border-color: rgba(200, 200, 200, 0);
}

.power-logo {
  margin-top: 50px;
  margin-right: 7vw;
}

.power-container {
  background-image: url(../assets/banner.png);
  max-width: 100%;
  height: auto;
  border: none;
  padding: 0;
  margin: 0;
  background-size: cover;
  padding: 5vw;
  height: auto;
}

.power-fist {
  background-image: url(../assets/fist.png);
  /* background-size: 100%; */
  background-repeat: no-repeat;
  /* height: auto; */
  border: none;
  padding: 0;
  margin: 0;
  color: white;
  max-height: 10%;
}

.power-text {
  color: white;
  max-width: 50%;
  font-size: 20px;
  margin: 0;
}

.logo-top {
  width: 35%;
  min-width: 200px;
}

.power-text-h1 {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
  font-size: 30pt;
  color: #ffffff !important;
}

.power-text-h2 {
  word-wrap: break-word;
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  line-height: 1.5em;
  font-size: 25pt;
  max-width: 80vw;
  margin-top: 0;
}

.power-text-normal {
  line-height: 1.8em;
  color: #ffffff !important;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  word-wrap: break-word;
  font-weight: 500;
  margin: 0;
  max-width: 50vw;
  font-size: 15pt;
}

.power-bold {
  font-weight: bolder;
}

.black-end {
  height: 37px;
  background-color: black;
  color: rgb(130, 130, 130);
  padding-top: 21px;
  text-align: left;
  padding-left: 5vw;
}

.rmaster {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin: 3vh 10vw;
}

.rheader {
  flex-grow: 1;
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 5vw;
}

.rleft,
.rleft-last {
  flex-grow: 1;
  flex-basis: 50%;
  /* padding-right: 10px;  */
  margin-bottom: 30pt;
}

rleft-last {
  margin-bottom: 10pt;
}

.rright,
.rright-last {
  flex-grow: 1;
  flex-basis: 50%;
  margin-bottom: 30pt;
}

.rright-last {
  margin-bottom: 10pt;
}

.r-margins-container {
  padding-right: 15pt;
}

.join-container {
  background-image: url(../assets/banner.png);
  max-width: 100%;
  /* height: 200px; */
  border: none;
  padding: 0;
  margin: 0;
  /* min-height: 572px; */
  background-size: cover;
  padding: 5%;
  height: auto;
}

.join-content {
  color: white;
}

.join-content-header {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 50px;
}

.join-content-text {
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 40px;
}

.join-content-link {
  text-decoration: none;
  color: white;
}

.join-content-link-div {
  margin-top: 30px;
  padding-bottom: 5vh;
  margin-right: 7vw;
}

.join-content-link-div-bottom {
  margin-top: 30px;
  padding-bottom: 5vh;
  /* margin-right: 7vw; */
}

.join-content-link-div-middle {
  margin-top: 20pt;
  /* padding-bottom: 5vh; */
  /* margin-right: 7vw; */
  /* background-color: black; */
}

.space {
  height: 18px;
}

.advert {
  width: 100%;
  /* background-color: red; */
  background-color: rgb(246, 248, 250);
  padding-top: 7%;
  padding-bottom: 5%;
}

.advert-inner {
  /* background-color: yellow; */
  background-color: rgb(246, 248, 250);
  margin-right: 10%;
  margin-left: 10%;
}

.advert-header {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #0054a5 !important;
  line-height: 1.2em;
  text-align: center;
  height: auto;
  margin: 5px;
  /* margin-top: 30px; */
}

.advert-text {
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  word-wrap: break-word;
  color: #222222;
  font-weight: 500;
  line-height: 1.3em;
  font-size: 120%;
}

.tsc {
  padding: 35px 0;
  height: auto;
}

.tsc-logo {
  color: white;
  background-color: white;
}

.tsc-button {
  margin-top: 10px;
  color: #0054a5 !important;
  /* color: white; */
  border-width: 0px !important;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  font-size: 18px;
  position: relative;
  padding: 0.3em 1em;
  border: 2px solid;
  cursor: pointer !important;
  transition: 0.3s;
  background-color: white;
}

.tsc-button:hover {
  background-color: rgb(242, 242, 242);
}

.ready {
  width: auto;
  height: auto;
  background-color: rgb(242, 242, 242);
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  word-break: normal;
  text-align: center;
}

.ready-header {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 45px;
  color: #0054a5 !important;
  line-height: 1.2em;
  text-align: center;
  height: auto;
  margin: 5px;
}

.ready-text {
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  line-height: 1.7em;
  /* margin-left: 30px; */
  margin-right: 30px;
  /* scroll-margin-right:30px; */
  /* padding: 0; */
  /* padding-bottom: 0px; */
  border: 0;
  outline: 0;
  background: 0 0;
  font-size: 20px;
  vertical-align: baseline;
  color: #0054a5 !important;
  height: auto;
}

.reasons-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 32% 26% 22%;
  height: 554px;
  justify-items: start;
}

.reasons-header-pad {
  padding-left: 20%;
  padding-top: 5%;
}

.reasons-text {
  line-height: 1.6em;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.6em;
  font-size: 100%;
}

.reasons-header1 {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  font-size: 36pt;
  color: #0054a5 !important;
  letter-spacing: 2pt;
}

.reasons-header2 {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-size: 19pt;
  color: #0054a5 !important;
  letter-spacing: 1pt;
  line-height: 1.2em;
  font-weight: bold;
  margin-right: 4vw;
}

.levels-container {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

.levels {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 4vh;
}

.levels-header {
  font-family: "Oswald", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #0054a5 !important;
  line-height: 1.2em;
  text-align: center;
  height: auto;
  margin: 5px;
}

.levels-small {
  font-size: 90%;
}

.table-container {
  overflow-x: auto;
}

.levels-table {
  font-family: "Open Sans", sans-serif !important;
  border-collapse: collapse;
  margin-bottom: 4vw;
  border: 1px solid lightgray;
  max-width: 800pt;
  margin-left: auto;
  margin-right: auto;
}

.th {
  line-height: 1em;
  padding: 10pt;
}

.thtd1,
.thtd2,
.thtd3,
.thtd4 {
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-size: 100%;
  color: white;
  margin: 0;
  min-width: 10vw;
  padding: 10pt;
}

.thtd1 {
  background-color: #0054a5;
}

.thtd2 {
  background-color: #7b7670;
}

.thtd3 {
  background-color: #f5a81f;
}

.thtd4 {
  background-color: #0e2a5c;
}

.tbtr {
  max-height: 50pt;
  height: 50pt;
}

.tbtd,
.tbtdr {
  max-height: 30pt;
  border: 1px solid lightgray;
  padding: 5pt;
  font-size: 90%;
  font-weight: bold;
}

.tbtdr {
  text-align: left;
}

.tbimg {
  max-height: 25pt;
}

.the-end {
  background-color: gray;
}

.imprint-format {
  text-decoration: none;
  color: inherit;
  padding: 5px;
  /* padding: 0px 10px; */
}

@media only screen and (max-width: 966px) {
  .main-content {
    margin-top: 0px;
  }

  .power-text-normal {
    max-width: 90vw;
  }

  .rmaster {
    flex-direction: column;
  }

  .reasons-header1 {
    font-size: 30pt;
    letter-spacing: 1pt;
  }

  .power-new {
    background-size: auto 25vw, cover;
  }
}

@media only screen and (max-width: 400pt) {
  .main-content {
    margin-top: 0px;
  }

  .power-text-normal {
    max-width: 82vw;
  }

  .power-new {
    background-size: auto 20vw, cover;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  transition: all 1s ease;
}

.modal-enter-to {
  opacity: 1;
}

.modal-leave {
  opacity: 1;
}

.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-leave-to {
  opacity: 0;
}
</style>
