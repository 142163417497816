<template>
  <div>
    <div style="margin-top: 50pt">
      <button @click="another">Export</button>
    </div>

    <table id="t01">
      <th style="width: 3%" @click="sort('isActive')">Active</th>
      <th style="width: 8%" @click="sort('firstName')">Firstname</th>
      <th style="width: 8%" @click="sort('lastName')">Lastname</th>
      <th style="width: 8%" @click="sort('companyName')">Company</th>
      <th style="width: 8%" @click="sort('country')">Country</th>
      <th style="width: 8%" @click="sort('city')">City</th>
      <th style="width: 8%" @click="sort('zip')">ZIP</th>
      <th style="width: 8%" @click="sort('street')">Street</th>
      <th style="width: 8%" @click="sort('eMailAddress')">Email</th>
      <th style="width: 8%" @click="sort('phoneNumber')">Phone</th>
      <th style="width: 8%" @click="sort('brand')">Brand</th>
      <th style="width: 8%" @click="sort('interests')">Interests</th>
      <th style="width: 5%" @click="sort('notes')">Notes</th>
      <th style="width: 5%" @click="sort('registrationTime')">
        Registration Time
      </th>
      <th style="width: 3%" @click="sort('portal')">Portal</th>
      <th style="width: 3%" @click="sort('newsLetter')">News Letter</th>
      <th style="width: 5%" @click="sort('distributors')">News Letter</th>

      <!-- <tr v-for="(reg, idx) in sortedRegistrations" v-bind:key="idx"> -->
      <tr v-for="reg in sortedRegistrations" v-bind:key="reg.id">
        <td style="width: 3%">{{ reg.isActive }}</td>
        <td style="width: 8%">{{ reg.firstName || "" }}</td>
        <td style="width: 8%">{{ reg.lastName || "" }}</td>
        <td style="width: 8%">{{ reg.companyName || "" }}</td>
        <td style="width: 8%">{{ getCountry(reg) || "" }}</td>
        <td style="width: 8%">{{ reg.city || "" }}</td>
        <td style="width: 8%">{{ reg.zip || "" }}</td>
        <td style="width: 8%">{{ reg.street || "" }}</td>
        <td style="width: 9%">{{ reg.eMailAddress || "" }}</td>
        <td style="width: 8%">{{ reg.phoneNumber || "" }}</td>
        <td style="width: 8%">{{ reg.brand || "" }}</td>
        <td style="width: 8%">{{ reg.interests || "" }}</td>
        <td style="width: 5%">{{ reg.notes || "" }}</td>
        <td style="width: 5%">{{ formatDate(reg.registrationTime) || "" }}</td>
        <td style="width: 3%">{{ formatPortal(reg.portal) }}</td>
        <td style="width: 3%">
          {{ reg.newsLetter && reg.newsLetter == 2 ? true : false }}
        </td>
        <td style="width: 5%">{{ reg.distributors || "" }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  name: "ShowData",
  mounted() {
    this.getRegistrations();
  },
  props: {},
  data() {
    return {
      appPath: process.env.VUE_APP_API_ENDPOINT,
      registrations: [],
      currentSort: "name",
      currentSortDir: "asc"
    };
  },
  methods: {
    getCountry(registration) {
      return registration.country.name;
    },
    getRegistrations() {
      let self = this;
      const url = this.appPath + "/registration";
      let options = {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "upgrade-insecure-requests": "1"
        }
      };
      fetch(url, options)
        .then(resp => resp.json())
        .then(data => {
          self.registrations = data;
        });
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    conv() {
      return this.convert(this.registrations, "PartnerProgramReport", true);
    },
    another() {
      let copy = this.deepCopy(this.registrations);
      copy.sort((a, b) => {
        let comparison = 0;
        if (a.registrationTime > b.registrationTime) comparison = -1;
        if (a.registrationTime < b.registrationTime) comparison = 1;
        return comparison;
      });

      let arrayLength = copy.length;
      for (let i = 0; i < arrayLength; i++) {
        copy[i].country = copy[i].country.name;
      }

      var newWb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(copy);
      XLSX.utils.book_append_sheet(newWb, ws, "PartnerProgram");
      return XLSX.writeFile(newWb, "PartnerProgram." + "xlsx");
    },
    convert(JSONData, ReportTitle, ShowLabel) {
      var arrData =
        typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
      var CSV = "sep=," + "\r\n\n";
      if (ShowLabel) {
        let row = "";
        for (let index in arrData[0]) {
          row += index + ",";
        }
        row = row.slice(0, -1);
        CSV += row + "\r\n";
      }
      for (var i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }
        row.slice(0, row.length - 1);
        CSV += row + "\r\n";
      }
      if (CSV == "") {
        alert("Invalid data");
        return;
      }
      var fileName = ReportTitle.replace(/ /g, "_");
      var uri = "data:text/csv;charset=utf-8," + escape(CSV);
      var link = document.createElement("a");
      link.href = uri;
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formatDate(dtString) {
      return dtString.slice(0, 16).replace("T", "  ");
    },
    formatPortal(portal) {
      switch (portal) {
        case -1: // As of 2020-02-14 we do not have the checkbox anymore and every registration will have this value
          return "n/a";
        case 0: // Pre 2020-02-14: portal was not checked
        case 1: // Pre 2020-02-14: portal was checked but customer never dooble-opted
          return "false";
        case 2:
          return "true"; // Before 2020-02-14: portal was checked and customer double-opted
        default:
          return "errorneous value: " + portal;
      }
    },
    deepCopy(inObject) {
      let outObject, value, key;

      if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
      }

      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {};

      for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopy(value);
      }

      return outObject;
    }
  },
  computed: {
    sortedRegistrations() {
      return this.registrations.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 15px;
  text-align: left;
}
th {
  cursor: pointer;
}
table#t01 tr:nth-child(even) {
  background-color: #eee;
}
table#t01 tr:nth-child(odd) {
  background-color: #fff;
}
table#t01 th {
  background-color: black;
  color: white;
}
</style>
