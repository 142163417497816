<template>
  <div>
    <div style="margin-top: 50pt">
      <button @click="copyToCrmAction">Copy To Crm</button>
    </div>
    <table id="t01">
      <th @click="sort('isActive')">Active</th>
      <th @click="sort('firstName')">Firstname</th>
      <th @click="sort('lastName')">Lastname</th>
      <!-- <th  @click="sort('companyName')">Company</th>
      <th @click="sort('country')">Country</th> -->
      <th @click="sort('city')">City</th>
      <th @click="sort('zip')">ZIP</th>
      <th @click="sort('street')">Street</th>
      <th @click="sort('eMailAddress')">Email</th>
      <th @click="sort('phoneNumber')">Phone</th>
      <th @click="sort('brand')">Brand</th>
      <th @click="sort('notes')">Notes</th>
      <!-- <th  @click="sort('registrationTime')">Registration Time</th>
      <th @click="sort('portal')">Portal</th> -->
      <th @click="sort('newsLetter')">News Letter</th>
      <th @click="sort('crmIntegrated')">Integrated</th>

      <!-- <tr v-for="(reg, idx) in sortedRegistrations" v-bind:key="idx"> -->
      <tr v-for="reg in sortedRegistrations" v-bind:key="reg.id">
        <td>{{ reg.isActive }}</td>
        <td>{{ reg.firstName || "" }}</td>
        <td>{{ reg.lastName || "" }}</td>
        <!-- <td>{{ reg.companyName || "" }}</td>
        <td>{{ getCountry(reg) || "" }}</td> -->
        <td>{{ reg.city || "" }}</td>
        <td>{{ reg.zip || "" }}</td>
        <td>{{ reg.street || "" }}</td>
        <td>{{ reg.eMailAddress || "" }}</td>
        <td>{{ reg.phoneNumber || "" }}</td>
        <td>{{ reg.brand || "" }}</td>
        <td>{{ reg.notes || "" }}</td>
        <!-- <td >{{ formatDate(reg.registrationTime) || "" }}</td>
        <td>{{ formatPortal(reg.portal) }}</td> -->
        <td>
          {{ reg.newsLetter && reg.newsLetter == 2 ? true : false }}
        </td>
        <td>{{ reg.crmIntegrated == true ? true : false }}</td>
      </tr>
    </table>

    <div align="left">
      <ul>
        <li v-for="registration in registrations" v-bind:key="registration.id">
          {{ registration.firstName }}
          {{ registration.lastName }}
          {{ registration.companyName }}
          CrmIntegrated: {{ registration.crmIntegrated }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyToCrm",
  mounted() {
    this.getRegistrations();
  },
  props: {},
  data() {
    return {
      appPath: process.env.VUE_APP_API_ENDPOINT,
      registrations: [],
      currentSort: "name",
      currentSortDir: "asc"
    };
  },
  methods: {
    getRegistrations() {
      let self = this;
      const url = this.appPath + "/copyToCrm?copy=false";
      let options = {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "upgrade-insecure-requests": "1"
        }
      };
      fetch(url, options)
        .then(resp => resp.json())
        .then(data => {
          self.registrations = data;
        });
    },
    copyToCrmAction() {
      let self = this;
      const url = this.appPath + "/copyToCrm?copy=true";
      let options = {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "upgrade-insecure-requests": "1"
        }
      };
      fetch(url, options)
        .then(resp => resp.json())
        .then(data => {
          self.registrations = data;
        });
    }
  },
  computed: {
    sortedRegistrations() {
      return this.registrations.slice().sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  display: block;
  /* overflow-x: auto; */
  /* overflow: scroll; */
  /* white-space: nowrap; */
  overflow-x: auto;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  /* padding: 15px; */
  text-align: left;
  /* white-space: nowrap; */
  width: 1px;
}
th {
  cursor: pointer;
}
table#t01 tr:nth-child(even) {
  background-color: #eee;
}
table#t01 tr:nth-child(odd) {
  background-color: #fff;
}
table#t01 th {
  background-color: black;
  color: white;
}
</style>
